import { createApp } from 'vue'
import { Quasar, Notify, Dark } from 'quasar'

import lang from './plugins/lang'
import api from './plugins/api'
import router from './router'
import store from './store'
import App from './App.vue'

// Css, icons...
import iconSet from 'quasar/icon-set/material-icons'
import 'quasar/src/css/index.sass'
import '@quasar/extras/roboto-font/roboto-font.css'
import '@quasar/extras/material-icons/material-icons.css'
import './main.scss'

// Monkey patch inputs
// import QFile from 'quasar/src/components/file/QFile'
// import QInput from 'quasar/src/components/input/QInput'
// import QField from 'quasar/src/components/field/QField'
// import QSelect from 'quasar/src/components/select/QSelect'
// [QFile, QInput, QField, QSelect].forEach(c => {
//     c.props.filled = {
//         type: Boolean,
//         default: true,
//     };
// });

// Create the app
const quasarOptions = {
    iconSet,
    plugins: { Notify, Dark },
    config: {
        dark: 'auto',
        notify: {
            position: 'top',
            progress: true,
            type: 'info',
        },
    }
};

const app = createApp(App)

app.use(Quasar, quasarOptions)
app.use(api)
app.use(lang)
app.use(store)
app.use(router)
app.mount('#app')
