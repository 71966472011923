let translations = {};

window.__ = function(string, replacements = null, arrayAsUl = false) {
    let translation = translations[string] || `__${string}__`;

    // Insert any dynamic replacements
    if (replacements) {
        const replacementKeys = Object.keys(replacements);

        if (replacementKeys.length) {
            replacementKeys.forEach(key => {
                translation = translation.replace(`:${key}`, replacements[key]);
            });
        }
    }

    // If the translation is not an array, return it
    if (typeof translation === 'string' || !Array.isArray(translation)) {
        return translation;
    }

    // If the translation is an array and we want a list output
    if (!arrayAsUl) {
        return '<ul>' + translation.map(l => `<li>${l.replace(/^(- |\* )/, '')}</li>`).join('') + '</ul>';
    }

    // Else, join each line with a newline
    return translation.join('\n');
}

__.set = function(strings) {
    translations = strings;
}

export default {
    install(app, strings) {
        if (strings) {
            __.set(strings);
        }

        app.config.globalProperties.__ = __;
    }
}
