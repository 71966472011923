import { createRouter, createWebHistory, RouterView } from 'vue-router';
import useAuth from './stores/auth';

const router = createRouter({
    history: createWebHistory('/'),
    linkActiveClass: 'active',
    routes: [
        {
            path: '/login',
            component: () => import('./pages/Login.vue'),
            meta: { guest: true },
        },
        {
            path: '/',
            component: () => import('./pages/Home.vue'),
        },
    ],
});

router.beforeEach(async(to, from, next) => {
    const auth = useAuth();

    // If not logged in, check if we have an active session
    if (!auth.loggedIn) await auth.load();

    // If logged in and accessing a guest route, redirect to app
    if (auth.loggedIn && to.meta.guest) return next('/');

    // If not logged in and accessing an app route, redirect to login
    if (!auth.loggedIn && !to.meta.guest) return next('/login');

    return next();
});

export default router;
