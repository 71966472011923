import { defineStore } from 'pinia';
import { Cookies } from 'quasar';

export default defineStore('auth', {
    state: () => ({
        user: null,
        token: null,
        admin: false,
        tenant: window.location.host.split('.')[0],
    }),

    getters: {
        loggedIn(state) {
            return !!state.user?.id;
        },
    },

    actions: {
        prepareApi() {
            const token = localStorage.getItem('token');

            this.$api.set('headers.common.Authorization', `Bearer ${token}`);
            this.$api.set('headers.common.X-Tenant', this.tenant);
        },

        async load() {
            try {
                this.prepareApi();
                this.user = await this.$api.get('auth') || null;
                console.log(await this.$api.get('test'));
            } catch(e) {
                console.error(e);
            }

            this.admin = false;
        },

        async login({ email, password, remember }) {
            this.prepareApi();

            localStorage.removeItem('token');

            await this.$api.get('/sanctum/csrf-cookie');
            const token = await this.$api.post('login', { email, password, remember });

            if (token) {
                localStorage.setItem('token', token);
            }
        },

        async logout() {
            this.prepareApi();

            await this.$api.post('logout');
            window.location.href = '/';
        },

        async requestPassword({ email }) {
            this.prepareApi();

            const res = await this.$api.post('forgot-password', { email });
            return res?.message;
        },

        async resetPassword({ token, email, password, password_confirmation }) {
            this.prepareApi();

            const res = await this.$api.post('reset-password', { token, email, password, password_confirmation });
            return res?.message;
        }
    }
});
