<template>
    <q-layout
        v-if="ready"
        :class="$auth.loggedIn ? 'auth' : 'guest'"
        view="hHh lpR fFf"
        class="bg-grey-1"
    >
        <template v-if="$auth.loggedIn">
            <q-header
                bordered
                height-hint="64"
                class="bg-white text-grey-8"
                id="header"
            >
                <q-toolbar>
                    <q-btn
                        flat dense round
                        @click="drawer = !drawer"
                        aria-label="Menu"
                        icon="menu"
                    />

                    <q-toolbar-title>{{title || appName}}</q-toolbar-title>

                    <q-space />

                    <q-btn
                        flat dense round
                        aria-label="User"
                        icon="person"
                    >
                        <q-menu>
                            <q-list>
                                <q-item-label header>{{$auth.user.name}}</q-item-label>

                                <q-item clickable v-close-popup @click="$auth.logout">
                                    <q-item-section avatar><q-icon name="logout" /></q-item-section>
                                    <q-item-section>{{__('auth.logout')}}</q-item-section>
                                </q-item>
                            </q-list>
                        </q-menu>
                    </q-btn>
                </q-toolbar>
            </q-header>

            <q-drawer
                v-model="drawer"
                show-if-above
                bordered
                :width="280"
                class="bg-white"
                id="drawer"
            >
                <q-list>
                    <!--  -->
                </q-list>
            </q-drawer>
        </template>

        <q-page-container id="content">
            <router-view />
        </q-page-container>

        <cookie-consent />
    </q-layout>
</template>

<script>
export default {
    data() {
        return {
            ready: false,
            drawer: false,
            appName: "",
            title: "",
            translations: "",
        };
    },

    methods: {
        setTitle(title) {
            this.title = title;
            document.title = [this.title, this.appName].filter(Boolean).join(" - ");
        }
    },

    beforeCreate() {
        this.$api.get("config").then(res => {
            this.appName = res.name;
            this.translations = res.translations;
        });
    },

    watch: {
        "translations": {
            handler(strings) {
                this.__.set(strings);
                this.ready = !!strings && Object.values(strings).length;
            },
        },
        "appName": {
            handler(name) {
                this.appName = name;
                this.setTitle();
            }
        },
    },
}
</script>

<style scoped>
.guest {
    display: grid;
    place-items: center;
}

.guest :deep(.q-page) {
    width: 100vw;
    max-width: 400px;
    min-height: 0 !important;
}
</style>
