import axios from 'axios';
import { set } from 'lodash';

const api = axios.create({
    baseURL: '/api',
    withCredentials: true,
});

api.defaults.headers.common['Accept'] = 'application/json';

api.interceptors.request.use(
    config => {
        if (config.url.startsWith('/') || config.url.startsWith('http')) {
            config.baseURL = '';
        }

        if (config.method === 'get') {
            for (let param in config.params) {
                if (typeof config.params[param] === 'boolean') {
                    config.params[param] = !!config.params[param] ? 1 : 0;
                }
            }
        }

        return config;
    },
    error => error,
);

api.interceptors.response.use(
    response => {
        return response.data;
    },
    error => {
        if (!error.status) {
            return Promise.reject(error);
        }

        if ([401, 419].includes(error.response.status)) {
            return location.reload();
        }

        console.warn(error, error.response.data);
        return Promise.reject(error);
    },
);

api.postOrPatch = (url, data, options) => {
    if (!!data.id) {
        return api.patch(`${url}/${data.id}`, data, options);
    } else {
        return api.post(url, data, options);
    }
};

api.postOrPatchMultipart = (url, data, options) => {
    if (!!data.id){
        return api.patchMultipart(`${url}/${data.id}`, data, options);
    } else {
        return api.postMultipart(url, data, options);
    }
};

api.postMultipart = (url, data, options) => {
    return api.post(url, objectToFormData({ ...data }), options);
};

api.patchMultipart = (url, data, options) => {
    return api.post(url, objectToFormData({ ...data, _method: 'PATCH' }), options);
};

api.set = (key, value) => {
    set(api.defaults, key, value);
}

export { api };

export default {
    install(app, options) {
        for (let o in options) {
            api.set(o, options[o]);
        }
        app.config.globalProperties.$api = api;
        app.provide('api', api);
    }
}

/**
 * Convert a regular Object to FormData
 * https://github.com/therealparmesh/object-to-formdata
 *
 * @param {Object} obj,
 * @param {Object} cfg,
 * @param {FormData} fd,
 * @param {String} pre
 */
const objectToFormData = (obj, cfg, fd, pre) => {
    const isUndefined = value => value === undefined;
    const isNull = value => value === null;
    const isObject = value => value === Object(value);
    const isArray = value => Array.isArray(value);
    const isDate = value => value instanceof Date;
    const isBlob = value => value && typeof value.size === 'number' && typeof value.type === 'string' && typeof value.slice === 'function';
    const isFile = value => isBlob(value) && typeof value.name === 'string' && (typeof value.lastModifiedDate === 'object' || typeof value.lastModified === 'number');
    const isBoolean = value => typeof value === 'boolean';

    cfg = cfg || {};
    cfg.nullsAsUndefineds = isUndefined(cfg.nullsAsUndefineds) ? false : cfg.nullsAsUndefineds;
    fd = fd || new FormData();

    if (isUndefined(obj)) {
        return fd;

    } else if (isBoolean(obj)) {
        fd.append(pre, obj ? 1 : 0);

    } else if (isNull(obj)) {
        if (!cfg.nullsAsUndefineds) {
            fd.append(pre, '');
        }

    } else if (isArray(obj)) {
        if (!obj.length) {
            fd.append(`${pre}[]`, '');
        } else {
            obj.forEach((value, index) => objectToFormData(value, cfg, fd, `${pre}[${index}]`));
        }

    } else if (isDate(obj)) {
        fd.append(pre, obj.toISOString());

    } else if (isObject(obj) && !isFile(obj) && !isBlob(obj)) {
        if (pre && Object.keys(obj).length === 0) {
            return fd.append(`${pre}[]`, null);
        }

        Object.keys(obj).forEach(prop => {
            const value = obj[prop];
            if (isArray(value)) {;
                while (prop.length > 2 && prop.lastIndexOf('[]') === prop.length - 2) {
                    prop = prop.substring(0, prop.length - 2);
                }
            }
            objectToFormData(value, cfg, fd, pre ? `${pre}[${prop}]` : prop);
        });

    } else {
        fd.append(pre, obj);

    }

    return fd;
}
