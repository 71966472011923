<template>
    <q-dialog :modelValue="!!cookies" persistent>
        <q-card>
            <q-card-section>
                <div class="text-h6">Cookies</div>
            </q-card-section>

            <q-card-section class="q-pt-none">
                <p>
                    Dette nettstedet lagrer følgende cookies som del av kjernefunksjonalitet knyttet til sikkerhet og pålogging.
                    Disse cookiene er nødvendige for bruk av systemet, og de lagres derfor uten foregående samtykke.
                </p>

                <p>
                    Ingen av våre cookies leveres av tredjepart eller lagrer personlig informasjon.
                </p>

                <br>

                <div v-for="cookie in cookies">
                    <b>{{cookie.name}}</b>
                    <p>{{cookie.description}}</p>
                </div>
            </q-card-section>

            <q-card-actions align="right">
                <q-btn flat :label="__('button.accept')" color="primary" @click="accept" />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script>
import { Cookies } from 'quasar'

export default {
    data() {
        return {
            cookies: null,
            name: 'cookie_consent',
        }
    },

    methods: {
        async load() {
            if (Cookies.has(this.name)) {
                return this.cookies = null;
            }

            this.cookies = await this.$api.get('cookie-info');
        },

        accept() {
            Cookies.set(this.name, Date.now(), { expires: 3650, path: '/' });

            this.load();
        },
    },

    created() {
        this.load();
    }
}
</script>

<style scoped>
</style>
