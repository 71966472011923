import { createPinia } from 'pinia';

import auth from './stores/auth';

export default {
    install(app, options) {
        const pinia = createPinia();

        pinia.use(ctx => ({
            $api: ctx.app.config.globalProperties.$api,
            $route: ctx.app.config.globalProperties.$route,
            $router: ctx.app.config.globalProperties.$router,
        }));

        app.use(pinia);
        app.config.globalProperties.$auth = auth();
    }
}
